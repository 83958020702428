import React, { useCallback, useState } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Grid } from "@mui/material";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useHistory } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { useDispatch, useSelector } from "react-redux";
import { updateEntityTree } from "../../js/redux/actions/userPref";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { historyPush } from "../../js/utils/genericMethods";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { toastr } from "react-redux-toastr";
import Axios from 'axios';
import { errorMessageDisplay } from "../../../src/js/utils/backend"
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";


const OTEntityGrid = ({
    label = t("grid.title.Entities"),
    baseURL = r.oTEntity,
    ...props
}) => {
    const apiRef = useGridApiRef();
    const isCompanyManager = useSelector(state => state.auth.CompanyManager);
    const isHoldingManager = useSelector(state => state.auth.HoldingManager);
    const isEntityManager = useSelector(state => state.auth.EntityManager);
    const addSubEntity = isEntityManager && !isCompanyManager && !isHoldingManager;
    const deleteEntity = isCompanyManager;
    const { EntityManagerEntityIDs } = useSelector(state => state.auth);
    const addRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const editingRights = entityID =>
        isCompanyManager || isHoldingManager || EntityManagerEntityIDs?.includes(entityID);
    const history = useHistory();
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState(false);
    const [entityToDelete, setEntityToDelete] = useState(null);
    const [confirmationCode, setConfirmationCode] = useState("");

    const onDelete = useCallback(
        () => dispatch(updateEntityTree()),
        [dispatch]
    );

    const handleDeleteClick = (entity) => {
        setEntityToDelete(entity);
        setOpenDialog(true);
    };

    const token = useSelector(state => state.auth.Token);

    const handleConfirmDelete = async () => {
        if (entityToDelete && confirmationCode.toLowerCase() === entityToDelete.Code.toLowerCase()) {
            const config = {
                headers: { Authorization: "Bearer " + token },
            };
            try {
                const entityID = entityToDelete.ID[0]==='#' ? entityToDelete.ID.substring(1) : entityToDelete.ID;
                await Axios.delete(`${baseURL}/delete/${entityID}`, config);
                onDelete(entityID);
                setOpenDialog(false);
                setEntityToDelete(null);
                setConfirmationCode("");
                dispatch(updateEntityTree());
                apiRef?.current.updateRows([{ ID: entityID, _action: "delete" }]);
            } catch (error) {
                toastr.error(errorMessageDisplay(error));
                setOpenDialog(false);
            } 
        }
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
        setEntityToDelete(null);
        setConfirmationCode("");
    };

    const gridSpecificColumns = [
        {
            field: "Code",
            headerName: t("field.Code"),
            flex: 1,
            editable: false,
        },
        {
            field: "Name",
            headerName: t("field.Name"),
            flex: 1,
            editable: false,
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        editingRights(params.row.ID) && (
            <GridActionsCellItem
                icon={
                    <BasicTooltip title={t("common.EditTreeVisibility")}>
                        <AccountTreeOutlinedIcon />
                    </BasicTooltip>
                }
                label={t("common.EditTreeVisibility")}
                className="textPrimary"
                onClick={() =>
                    historyPush(
                        history,
                        `/entity-tree-visibility-editor/${params.row.ID}`
                    )
                }
                color="inherit"
                disabled={isLocalLoading}
            />
        ),
        editingRights(params.row.ID) && (
            <GridActionsCellItem
                icon={
                    <BasicTooltip title={t("common.EditEntityExtent")}>
                        <MyLocationOutlinedIcon />
                    </BasicTooltip>
                }
                label={t("common.EditEntityExtent")}
                className="textPrimary"
                onClick={() =>
                    historyPush(history, `/entity-map-editor/${params.row.ID}`)
                }
                color="inherit"
                disabled={isLocalLoading}
            />
        ),
        editingRights(params.row.ID) && (
            <GridActionsCellItem
                icon={
                    <BasicTooltip title={t("common.Edit")}>
                        <EditOutlinedIcon />
                    </BasicTooltip>
                }
                label={t("common.Edit")}
                className="textPrimary"
                onClick={() =>
                    historyPush(history, `/entity/edit/${params.row.ID}`)
                }
                color="inherit"
                disabled={isLocalLoading}
            />
        ),
        editingRights(params.row.ID) && (
            <GridActionsCellItem
                icon={
                    <BasicTooltip title={t("common.Delete")}>
                        <DeleteIcon  />
                    </BasicTooltip>
                }
                label={t("common.Delete")}
                className="textPrimary"
                onClick={() => handleDeleteClick(params.row)}
                color="inherit"
                disabled={isLocalLoading || !deleteEntity}
            />
        )
    ].filter(Boolean); // Ensure we filter out `false` elements.

    return (
        <>
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                toolbar={{
                    newAction: (
                        <Button
                            color="secondary"
                            variant="text"
                            size="small"
                            startIcon={<GridAddIcon />}
                            onClick={() =>
                                historyPush(history, "/entity/create/new")
                            }
                        >
                            {addSubEntity ? t("common.AddSubEntity") : t("common.Add")}
                        </Button>
                    ),
                    add: false,
                }}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                isDeleteDisabled={true}
                //onDelete={onDelete}
                editingRights={addRights}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Name", sort: "desc" }],
                    },
                }}
                {...props}
            />

            <Dialog open={openDialog} onClose={handleCancelDelete} sx={{ w: 1 }} fullWidth> 
                <DialogContent>
                    <Box sx={{ typography:"bodyMajor"}}>
                        {t("common.entitydelete")} <b>{`${entityToDelete?.Code} - ${entityToDelete?.Name}. `}</b>
                        {t("common.operationundone")}
                        <br /> <br />
                        {t("common.entitycode")} {`"${entityToDelete?.Code}"`}
                    </Box>
                    <br/>
                    <Grid item xs={12}>
                    <TextField
                        autoFocus
                        label={t("field.Code")}
                        fullWidth
                        value={confirmationCode}
                        size="small"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <BasicButton onClick={handleCancelDelete} color="primary">
                        {t("common.Cancel")}
                    </BasicButton>
                    <BasicButton onClick={handleConfirmDelete} color="secondary" disabled={confirmationCode.toLowerCase() !== entityToDelete?.Code.toLowerCase()}>
                        {t("common.Confirm")}
                    </BasicButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OTEntityGrid;
