import SharedSettings from "../common/sharedSettings.json";

const DEU = {
    common: {
        AccessWorkflow: "Workflow zugreifen",
        AccessStationsGrid: "Auf das Stationsraster zugreifen",
        AccessStationsMap: "Auf die Stationskarte zugreifen",
        AccessZonesGrid: "Auf das Zonenraster zugreifen",
        AccessZonesMap: "Auf die Zonenkarte zugreifen",
        Actions: "Aktionen",
        Add: "Hinzufügen",
        AddAnElement: "Ein Element hinzufügen",
        AddSubEntity: "Unterentität hinzufügen",
        Assign: "Rolle zuweisen",
        AssignToInternalUsers: "Internen Benutzern zuweisen",
        AssignToExternalUsers: "Externen Benutzern zuweisen",
        AlertsOnly: "Nur Warnungen",
        AlertsToSee:
            "Es gibt einige Warnungen zu diesem Element. Weitere Informationen im Raster.",
        ApplicationDown: "Die Anwendung ist derzeit in Wartung",
        ApplicationUnderMaintenance: "Die Anwendung wird vom %{startDate} bis %{endDate} gewartet",
        Back: "Zurück",
        Bookmarks: "Lesezeichen",
        Cancel: "Abbrechen",
        CannotAccessThisPage: "Auf diese Seite kann nicht zugegriffen werden",
        Continue: "Fortfahren",
        ChangesHistory: "Änderungsverlauf",
        CoordinateSystem: "Koordinatensystem",
        CreatingNew: "Erstellung eines neuen",
        DefineA: "Definieren Sie ein",
        Delete: "Löschen",
        DeselectAll: "Alle abwählen",
        DontHaveAccessToAnyEntity: "Sie haben derzeit keinen Zugriff auf eine Entität",
        Download: "Herunterladen",
        Edit: "Bearbeiten",
        Editing: "Bearbeitung des",
        AddingEnglish: "Englischen Wert hinzufügen für",
        EditEntityExtent: "Entitätsausdehnung bearbeiten",
        EditZone: "Zone bearbeiten",
        EditTreeVisibility: "Baumsichtbarkeit bearbeiten",
        ExcelExport: "Excel-Export",
        False: "Falsch",
        FullName: "Vollständiger Name",
        GetResults: "Ergebnisse erhalten",
        GINTExport: "gINT-Export",
        GridView: "Rasteransicht",
        Group: "Gruppe",
        Help: "Hilfe",
        Hidden: "Versteckt",
        Loading: "Laden...",
        Location: "Standort",
        Mandatory: "Verpflichtend",
        MapView: "Kartenansicht",
        Move: "Bewegen",
        New: "Neu",
        NewFeaturesAreAdded: "Neue Funktionen wurden hinzugefügt",
        Next: "Weiter",
        NoAlerts: "Keine Warnungen anzuzeigen",
        NoResults: "Keine Ergebnisse anzuzeigen",
        OneTsigane: "Ein Zigeuner",
        OpenNotifications: "Benachrichtigungen öffnen",
        Overwrite: "Überschreiben",
        QuickSearchInto: "Schnellsuche in:",
        QuickSearchFilter: "Schnellsuchfilteroptionen",
        ParentFieldIsRequired: "Das Elternfeld ist erforderlich.",
        PreviewFile: "Datei Vorschau",
        Private: "Privat",
        RedirectTo: "Weiterleiten an:",
        Refresh: "Aktualisieren",
        ResultsFor: "Ergebnisse für %{value}",
        RemoveAccessRight: "Zugriffsrechte entfernen",
        Search: "Suchen",
        SearchInTable: "In der Tabelle suchen",
        SeeAlerts: "Warnungen anzeigen",
        SeeMore: "Mehr anzeigen",
        Select: "Auswählen",
        SelectAll: "Alle auswählen",
        SettingsPanel: "Einstellungsfeld",
        Skip: "Überspringen",
        SkipUpload: "Upload überspringen",
        Close: "Schließen",
        Remove: "Entfernen",
        Request: "Anfrage",
        Role: "Rolle",
        newRef: "Anfrage nach einer neuen Referenz",
        addNewRef: "Neue Referenz anfordern",
        Save: "Speichern",
        Translate: "Übersetzen",
        Tree: "Baum",
        True: "Wahr",
        Workflow: "Workflow",
        GenerateAToken: "Token generieren",
        CopytoClipboard: "In die Zwischenablage kopieren",
        CopiedSuccessfully: "Erfolgreich kopiert",
		SavedSuccessfully: "Erfolgreich gespeichert",
		featuresmessage: `Eine neue Version (Version ${SharedSettings.Version}) der Anwendung wurde veröffentlicht. Sie enthält folgende neue Funktionen:`,
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} erfolgreich hochgeladen",
        NoFileToDownload: "Keine Datei zum Herunterladen!",
        YouHaveAProblem: "Sie haben ein Problem",
        ContactForm: "Kontaktformular",
        YouHaveAnIdea: "Sie haben eine Idee",
        QuestionAboutTheApp: "Eine Frage zur App",
        WantToTrackAProblem: "Möchten Sie ein Problem verfolgen",
        QuestionAboutUsingTheToolWithBranches: "Eine Frage zur Verwendung des Tools mit Niederlassungen",
        BranchManagers: "Filialleiter"
    },
    diagram: {
        Draft: "Entwurf",
        Failed: "Fehlgeschlagen",
        Final: "Endgültig",
        Finalize: "Abschließen",
        ForValidation: "Zur Validierung",
        New: "Neu",
        NotValidated: "Nicht validiert",
        NotValid: "Nicht gültig",
        OK: "OK",
        Ok: "Ok",
        Processing: "In Bearbeitung",
        Reject: "Ablehnen",
        Rejected: "Abgelehnt",
        Remove: "Entfernen",
        Removed: "Entfernt",
        Requested: "Angefordert",
        Reset: "Zurücksetzen",
        Rework: "Überarbeiten",
        Reviewed: "Überprüft",
        Submit: "Einreichen",
        Submitted: "Eingereicht",
        UnderValidation: "In Validierung",
        Validated: "Validiert",
        Validate: "Validieren",
        Valid: "Gültig",
        Waiting: "Warten",
    },
    eddDataValidation: {
        Stations: "Stationen",
        Samplings: "Probenahmen",
        InSituUndergroundMeasObs: "In Situ Unterirdische Messungen",
        LaboResult: "Labore Ergebnisse",
        SpeciesIdentification: "Artenidentifikation",
        EddValidationMayTakeSomeMinutes: "Das Laden kann je nach Datenmenge im EDD einige Minuten dauern."
    },
    enum: {
        unitCategories: {
            NONE: "Keine",
            CONDUCTANCE: "Leitfähigkeit",
            CONDUCTIVITY: "Elektrische Leitfähigkeit",
            FLAMMABILITY: "Entflammbarkeit",
            HIGHT_DEPTH: "Höhe/Tiefe",
            LENGTH: "Länge",
            MASS: "Masse",
            MASS_CONCENTRATIONS: "Massenkonzentrationen",
            MASS_FRACTIONS: "Massenfraktionen",
            MICROBIAL_CELLS_CONCENTRATION: "Konzentration mikrobieller Zellen",
            PARTS_PER_MASS: "Teile pro Masse",
            PARTS_PER_VOLUME: "Teile pro Volumen",
            PERCENTAGE: "Prozentsatz",
            PRESSURE: "Druck",
            SPEED: "Geschwindigkeit",
            TEMPERATURE: "Temperatur",
            VOLTAGE: "Spannung",
            VOLUME: "Volumen",
            VOLUME_FLOW_RATE: "Volumenstrom",
            WATER_HARDNESS: "Wasserhärte",
            SPECIFIC_VOLUME: "Spezifisches Volumen",
            SOUND_INTENSITY: "Schallintensität",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Radioaktive Zerfallsrate",
            RADIOACTIVITY_PARTICLE_FLUX: "Radioaktiver Teilchenfluss"
        },
        frequencyQuery: {
            Monday: "Jeden Montag",
            Friday: "Jeden Freitag",
            BeginningMonth: "Jeden Anfang des Monats",
            EndMonth: "Jedes Ende des Monats",
        },
        rights: {
            COMPANYMANAGER: "Unternehmensmanager",
            HOLDINGMANAGER: "Holdingmanager",
            ENTITYMANAGER: "Entitätsmanager",
            BRANCHMANAGER: "Zweigstellenleiter",
            CONTRIBUTOR: "Mitwirkender",
            READER: "Leser",
            EXTERNAL: "Extern",
            GISMANAGER: "GIS-Manager",
        },
    },
    field: {
        AdditionalExplanations: "Zusätzliche Erklärungen",
        AnalysisGroup: "Analysegruppe",
        Author: "Autor",
        Basemap: "Grundkarte",
        Branch: "Zweigstelle",
        Cas: "CAS-Nummer",
        Category: "Kategorie",
        Code: "Code",
        Color: "Farbe",
        Confidential: "Vertraulich",
        Country: "Land",
        CreatedOn: "Erstellungsdatum",
        CreatedByUser: "Erstellt von Benutzer",
        CreatedByUserDescription: "Identität des Benutzers, der das Element erstellt hat",
        Description: "Beschreibung",
        DocumentCategory: "Dokumentenkategorie",
        EddFile: "EDD-Datei",
        EndDate: "Enddatum",
        Entity: "Einheit",
        EntityCode: "Einheitscode",
        EntityName: "Einheitsname",
        External: "Extern",
        ExternalProvider: "Externer Anbieter",
        Explaination: "Erklärung",
        Family: "Familie",
        File: "Datei",
        Filename: "Dateiname",
        Firstname: "Vorname",
        Frequency: "Häufigkeit",
        GisManager: "GIS-Manager",
        GroundAltitude: "Geländehöhe",
        InitialStudy: "Erststudie",
        IsPrimaryEDDFile: "Primärdokument des EDD",
        Key: "Schlüssel",
        Language: "Sprache",
        Lastname: "Nachname",
        LastUpdate: "Letzte Aktualisierung",
        LastUpdateDescription: "Datum und Uhrzeit der letzten Aktualisierung",
        LookUpTableName: "Lookup-Tabellenname",
        MapService: "Kartendienst",
        MaxValue: "Maximalwert",
        MinValue: "Minimalwert",
        Name: "Name",
        No: "Nein",
        NodeType: "Knotentyp",
        Note: "Notiz",
        Number: "Nummer",
        Observation: "Beobachtung",
        Owner: "Besitzer",
        Parent: "Übergeordnet",
        Phone: "Telefon",
        PhotoContext: "Fotokontext",
        PointCoordinates: "Punktkoordinaten",
        PolygonCoordinates: "Polygonkoordinaten",
        PublicationDate: "Veröffentlichungsdatum",
        Public: "Öffentlich",
        QueryName: "Abfragename",
        Reference: "Referenz",
        Region: "Region",
        RelatedZones: "Verwandte Zonen",
        RelatedStations: "Verwandte Stationen",
        RelatedStationGroups: "Verwandte Stationsgruppen",
        RelatedStudies: "Verwandte Studien",
        RequestedAccess: "Angeforderter Zugriff",
        RequestAccessType: "Zugriffsanforderungstyp",
        RequestDate: "Anforderungsdatum",
        RequestorIGG: "Anforderer IGG",
        RequestType: "Anforderungstyp",
        ResultsSize: "Ergebnisgröße",
        SamplingID: "Probenahme-ID",
        SubSamplingID: "Teilprobenahme-ID",
        SampleType: "Probentyp",
        SecurityGroupType: "Sicherheitsgruppentyp",
        Share: "Teilen",
        StartDate: "Startdatum",
        StartEndDate: "Start-/Enddatum",
        State: "Status",
        Station: "Station",
        StationAir: "Luft",
        StationBiodiversity: "Biodiversität",
        StationCode: "Stationscode",
        StationGroups: "Stationsgruppen",
        StationNoise: "Lärm",
        StationSedimentWater: "Wasser & Sediment",
        StationSediment: "Sediment",
        StationWater: "Wasser",
        StationSoilGroundwater: "Boden-Grundwasser",
        StationType: "Stationstyp",
        Study: "Studie",
        StudyType: "Studientyp",
        StudyYear: "Studienjahr",
        Submitter: "Einreicher",
        Title: "Titel",
        UpdatedByUser: "Aktualisiert von Benutzer",
        UpdatedByUserDescription: "Identität des letzten Benutzers, der das Element aktualisiert hat",
        User: "Benutzer",
        Units: "Einheiten",
        Value: "Wert",
        Valid: "Gültig",
        WorkOrder: "Arbeitsauftrag",
        Yes: "Ja",
        Zones: "Zonen",
        EmailNotif: "Benachrichtigungen per E-Mail erhalten",
        ExplanatoryText: "Technisches Token, das den Zugriff auf die APIs von One-Tsigane ermöglicht. Dieses Token ist streng persönlich: Ihre Identität wird bei der Verwendung referenziert. Es ist 365 Tage gültig:"
    },
    grid: {
        title: {
            Administration: "Verwaltung",
            Alerts: "Warnungen",
            AllUsers: "Alle Benutzer",
            Analytes: "Analyte",
            Branches: "Zweigstellen",
            BranchManagers: "Zweigstellenleiter",
            CompanyManagers: "Unternehmensleiter",
            HoldingManagers: "Holdingmanager",
            Configuration: "Konfiguration",
            UserPref: "Benutzereinstellungen",
            Countries: "Länder",
            Documents: "Dokumente",
            EDDFiles: "EDD-Dateien",
            Entities: "Einheiten",
            EntityManagers: "Einheitsleiter",
            EntityUsers: "Einheitsbenutzer",
            ExternalUsers: "Externe Benutzer",
            ExternalProviders: "Externe Anbieter",
            Families: "Familien",
            Files: "Dateien",
            GISManagers: "GIS-Manager",
            InternalUsers: "Interne Benutzer",
            LimitsOf: "Grenzen von",
            LookupHints: "Lookup-Hinweise",
            NewFeaturesMessage: "Nachricht über neue Funktionen",
            PhotoVideo: "Fotos und Videos",
            Queries: "Abfragen",
            Regions: "Regionen",
            Requests: "Zu validierende Anfragen",
            MyRequests: "Meine Anfragen",
            ReportScheduler: "Berichtszeitplaner",
            Settings: "Einstellungen",
            Samplings: "Probenahmen",
            Stations: "Stationen",
            VisibilityEditor: "Sichtbarkeitseditor",
            WorkorderExternalUsers: `Verwaltung externer Benutzerberechtigungen für Studie "%{value}", Arbeitsauftrag "%{value2}"`,
            Zones: "Zonen"
        },
    },
    tree: {
        Add: "Knoten zu %{value} hinzufügen",
        AddBookmark: "Zu Lesezeichen hinzufügen",
        AddStudy: "Studie zu %{value} hinzufügen",
        AddStationGroup: "Stationsgruppe hinzufügen",
        Delete: "%{value} löschen",
        Edit: "%{value} bearbeiten",
        EditTranslation: "%{value}-Übersetzungen bearbeiten",
        Entities: "Einheiten",
        EntityTreeTemplate: "Einheitentemplate",
        EntityTreeTemplateEditor: "Editor für Einheitentemplates",
        Limits: "Grenzen",
        Lookups: "Lookups",
        Maps: "Karten",
        References: "Referenzen",
        RemoveFromBookmarks: "Aus Lesezeichen entfernen",
        Security: "Sicherheit",
        StationGroupNodeEmpty: 
            "Derzeit gibt es keine Stationsgruppen zum Anzeigen. Klicken Sie hier, um eine neue zu erstellen",
        StudyTemplate: "Studientemplate",
        StudyTemplateEditor: "Editor für Studientemplate %{value}",
        Users: "Benutzer",
        Requests: "Anfragen",
        StudyType: "Studientyp",
        TextType: "Texttyp"
    },
    dialog: {
        Alerts: "Warnungen",
        AssignTo: "%{value} einem %{value2} zuweisen",
        AddNewLimits: "Neue Grenzen hinzufügen.",
        CopyZonesFromStudies: "Zonen aus anderen Studien kopieren.",
        OverwriteFileConfirmation: 
            "Diese Datei existiert bereits, möchten Sie den aktuellen Upload wirklich überschreiben?",
        PromptChangesHasOccured: 
            "Möchten Sie diese Seite wirklich verlassen?\n\nSie haben begonnen, eine Zeile in der aktuellen Datentabelle zu schreiben oder zu bearbeiten.\n\nDrücken Sie OK, um fortzufahren, oder Abbrechen, um auf der aktuellen Seite zu bleiben und Ihre Änderungen zu übernehmen.",
        DeleteConfirmationRow: "Möchten Sie diese Zeile wirklich löschen?",
        DeleteConfirmationNode: "Möchten Sie diesen Knoten wirklich löschen?",
        Cancelled: "Abgebrochen",
        SessionVerification: 
            "Ihre Sitzung wird überprüft. Vielen Dank für Ihre Geduld.",
        UploadSkipped: "Upload übersprungen",
        NotificationDoneConfirmation: 
            "Sind Sie sicher, dass Sie diese Benachrichtigung validieren möchten?"
    },
    input: {
        validation: {
            required: "Dieses Feld ist erforderlich.",
            DateRangeValidation: "Das Startdatum sollte vor dem Enddatum liegen.",
            DropFileAccepted: "Dateien hier ablegen",
            DropFileRejected: `Entschuldigung, es sind nur ${SharedSettings.MaxFilesToUpload} Dateien auf einmal erlaubt.`,
            NumberChar: "Die maximale Anzahl von Zeichen in diesem Feld beträgt:",
            WrongDocumentExtension: `Dies ist ein Dokumentenordner: Die akzeptierten Dateien dürfen keine Bilder oder Videos sein, daher wird ihr Hochladen abgelehnt.`,
            WrongPhotoVideoExtension: `Dies ist ein Foto-/Video-Ordner: Die akzeptierten Dateien sollten Bilder oder Videos sein, andernfalls wird ihr Hochladen abgelehnt.`,
            InvalidStartingDate: 
                "Das Startdatum darf nicht nach dem Enddatum liegen.",
            InvalidEndingDate: 
                "Das Enddatum darf nicht vor dem Startdatum liegen.",
            InvalidRange: "Der Wert entspricht nicht dem Bereich.",
            InvalidTime: "Die eingegebene Zeit ist ungültig. Bitte überprüfen Sie erneut. Die gültige Zeitspanne ist von 00:00 bis 23:59.",
            SelectStudy: "Bitte wählen Sie zuerst eine Studie aus."
        },
    },
    languages: {
        Dutch: "Niederländisch",
        English: "Englisch",
        French: "Französisch",
        German: "Deutsch",
        Portuguese: "Portugiesisch",
        Spanish: "Spanisch"
    },
    map: {
        addAPoint: "Einen neuen Punkt hinzufügen",
        coordinates: "Koordinaten",
        errorSIG: "Die Verbindung zu SIG konnte nicht hergestellt werden.",
        errorLayerSIG: 
            'Die Verbindung zur SIG-Ebene "%{layerName}" konnte nicht hergestellt werden.',
        entityOfInterest: "Interessierende Entität",
        extentOf: "Ausdehnung von",
        latitude: "Breitengrad",
        longitude: "Längengrad",
        mapServerLayers: "Kartenserver-Ebenen",
        point: "Punkt",
        polygon: "Polygon",
        removeAPoint: "Einen Punkt entfernen",
        redirectToStation: "Details der Station anzeigen",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Bilder",
            LAYER_PLAN: "Pläne",
            LAYER_TOPO: "Topographien",
            LAYER_PIPELINES: "Rohrleitungen",
            LAYER_MISC: "Verschiedenes",
            LAYER_CADASTRE: "Kataster",
            LAYER_ZONE: "Zonen"
        },
        selectedStations: "Ausgewählte Stationen",
        stationGroupsLayers: "Stationsgruppenschichten",
        stationsLayers: "Stationsschichten",
        stationsOf: "Stationen von",
        stationsOfGroup: "Stationen der Gruppe",
        zonesOf: "Zonen von",
        zonesExtents: "Zonenausdehnungen"
    },
    notification: {
        noNotification: "Sie haben keine Benachrichtigungen!",
        errorNotifictation: "Benachrichtigungen sind momentan nicht verfügbar."
    },
    misc: {
        lambdaUser: "Lambda-Benutzer",
        From: "von:"
    },
    panel: {
        EditEntity: "Entität bearbeiten",
        NewEntity: "Neue Entität",
        NewSubEntity: "Neue Unterentität"
    },
    placeholder: {
        Logout: "Abmelden",
        NoEntry: "Kein Eintrag",
        UserState: "Benutzerstatus",
        EddFileState: "EDD-Dateistatus"
    },
    select: {
        Contributor: "Mitwirkender",
        Reader: "Leser",
        External: "Extern"
    },
    view: {
        entityPage: {
            Code: "Code",
            Name: "Name",
            Country: "Land",
            Branch: "Zweigstelle",
            Parent: "Elternteil",
            Contact: "Kontakte",
            Documents: "Dokumente",
            History: "Verlauf",
            Home: "Startseite",
            Location: "Standort",
            Operations: "Operationen",
            Photo: "Foto"
        },
        helpPage: {
            Contact: "Kontakt",
            Documents: "Dokumente",
            InternalUsers: "Interne Benutzer",
            ExternalUsers: "Externe Benutzer",
            contact: {
                FileUploadedSuccessfully: "%{fileName} erfolgreich hochgeladen",
                NoFileToDownload: "Keine Datei zum Herunterladen!",
                AnyTechnicalIssues: "Technische Probleme",
                PleaseClickHere: "Bitte hier klicken",
                AnyFurtherFunctionalities: "Weitere Funktionen gewünscht",
                PleaseReferToThisLink: "Bitte diesem Link folgen",
                QuestionAboutTheApp: "Frage zur App",
                WantToTrackAProblem: "Ein Problem verfolgen möchten",
                ForSupportAtBranchLevel: "Für Support auf Filialebene wenden Sie sich bitte an"
            },
        },
        newUser: {
            CurrentRequests: "Aktuelle Anfragen",
            EntityAccessRequest: "Zugriff auf eine bestehende Entität anfordern",
            EntityCreateRequest: "Erstellen einer neuen Entität anfordern",
            Welcome: "Willkommen"
        },
        document: {
            AddMediaMetadata: "Metadaten hinzufügen zu:",
            AddDocument: "Dokument hinzufügen",
            CancelMove: "Verschieben abbrechen",
            CurrentStudy: "Aktuelle Studie:",
            Destination: "Zielort",
            DragAndDropFile: `Ziehen Sie eine Datei über den Bildschirm, um sie hochzuladen, oder verwenden Sie die 'Hinzufügen'-Schaltfläche in der Symbolleiste des Datengitters.`,
            Extension: "Erweiterung",
            FilesRejected: "Dateien wurden abgelehnt.",
            HelpFileUpload: "Hilfe zum Hochladen von Dateien",
            MaxFilesAllowed: `Eine Upload-Aktion erlaubt maximal ${SharedSettings.MaxFilesToUpload} Dateien auf einmal.`,
            MoveDocument: "Dokument verschieben",
            OriginalStudy: "Ursprüngliche Studie:",
            MoreInfo: "Mehr Informationen",
            ListAllowedExtensions: "Liste der erlaubten Dateierweiterungen",
            SelectDestination: "Zielort auswählen",
            UploadedBy: "Hochgeladen von:"
        },
        eddFiles: {
            getTemplate: "Dateivorlage abrufen"
        },
        bulkUpload: {
            getTemplate: "Dateivorlage abrufen"
        },
        limit: {
            add: "Neues Limit hinzufügen",
            addNewLimits: "Neue Limits hinzufügen",
            limitSelection: "Definition und Auswahl des Objekts",
            stepper: {
                CreateNewLimit: "Neues Limit erstellen",
                EditLimit: "Limit bearbeiten"
            },
            objectSelection: {
                object: "Objekt",
                parentObject: "Elternobjekt"
            },
        },
        query: {
            ranOn: "Ausgeführt am",
            ranOnThe: "ausgeführt am",
            reporting: "Berichterstattung",
            resultQuery: "Ergebnis der Abfrage",
            runQuery: "Abfrage ausführen",
            seeResultsOnMap: "Diese Ergebnisse auf einer Karte anzeigen",
            stepper: {
                CreateNewQuery: "Neue Abfrage erstellen",
                EditQuery: "Abfrage bearbeiten"
            },
            objectSelection: {
                objectSelection: "Objektauswahl",
                object: "Objekt",
                parentObject: "Elternobjekt",
                childObject: "Kinderobjekt",
                removeChild: "Kind entfernen"
            },
            conditions: {
                conditions: "Bedingungen"
            },
            outputSelection: {
                outputSelection: "Ausgabenauswahl",
                Fieldname: "Feldname",
                Label: "Etikett",
                Description: "Beschreibung",
                Show: "Anzeigen"
            },
        },
        request: {
            ADDUSER: "Benutzer hinzufügen",
            NEWENTITY: "Neue Entität",
            NEWREF: "Neue Referenz",
            OptionalObservation: "Optionale Beobachtung"
        },
        station: {
            CreateNewStation: "Neue Station erstellen",
            CreateStationGroup: "Eine Gruppe von Stationen erstellen",
            EditStation: "Station bearbeiten",
            StationGroup: "Stationsgruppe",
            StationStationGroup: "Stationen der Stationsgruppe:",
            StationGroupLoading: "Stationen der Stationsgruppe: wird geladen..."
        },
        study: {
            Study: "die Studie",
            StudyWorkOrder: "Studie %{value}: Arbeitsaufträge",
            NoStudyWorkOrder: "Studie ... : Arbeitsaufträge"
        },
        workOrder: {
            EDDFiles: "EDD-Dateien",
            ExternalUsersPermissions: "Berechtigungen für externe Benutzer"
        },
        zone: {
            CopyZones: "Zonen kopieren",
            CopyZonesFromStudy: "Zonen aus einer anderen Studie kopieren",
            CreateNewZone: "Neue Zone erstellen für",
            EditExtent: "Ausdehnung bearbeiten von",
            EditZone: "Zone bearbeiten",
            ExtendDefined: "Ausdehnung definiert",
            ExtendNotDefined: "Ausdehnung nicht definiert",
            LoadingEntity: "Entität wird geladen...",
            PreExistingZones: "Vorhandene Zonen von"
        },
    },
    packages: {
        colorPicker: {
            language: "DEU",
            color: "Farbe",
            rgb: "rgb",
            R: "R",
            G: "G",
            B: "B",
            hsv: "hsv",
            H: "H",
            S: "S",
            V: "V",
            hsl: "hsl",
            WrongFormat: "Falsches Format.",
            NotHexFormat: "Kein Hex-Wert.",
            transparent: "Transparent",
            none: "Ungültig"
        },
        reactAwesomeQueryBuilder: {
            and: "und",
            or: "oder",
            valueLabel: "Wert",
            valuePlaceholder: "Wert",
            fieldLabel: "Feld",
            operatorLabel: "Operator",
            funcLabel: "Funktion",
            fieldPlaceholder: "Feld auswählen",
            funcPlaceholder: "Funktion auswählen",
            operatorPlaceholder: "Operator auswählen",
            lockLabel: "Sperren",
            lockedLabel: "Gesperrt",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Gruppe hinzufügen",
            addRuleLabel: "Regel hinzufügen",
            addSubRuleLabel: "Unterregel hinzufügen",
            notLabel: "Nicht",
            valueSourcesPopupTitle: "Wertquelle auswählen",
            removeRuleConfirmOptions: {
                title: "Sind Sie sicher, dass Sie diese Regel löschen möchten?",
                okText: "Ja",
                okType: "Gefahr",
                cancelText: "Abbrechen"
            },
            removeGroupConfirmOptions: {
                title: "Sind Sie sicher, dass Sie diese Gruppe löschen möchten?",
                okText: "Ja",
                okType: "Gefahr",
                cancelText: "Abbrechen"
            },
            like: "wie",
            not_like: "nicht wie",
            starts_with: "beginnt mit",
            ends_with: "endet mit",
            between: "zwischen",
            not_between: "nicht zwischen",
            is_null: "ist null",
            is_not_null: "ist nicht null",
            is_empty: "ist leer",
            is_not_empty: "ist nicht leer",
            select_any_in: "irgendein in",
            select_not_any_in: "kein in",
            proximity: "Nähe",
            enterValue: "Wert eingeben",
            enterNumber: "Nummer eingeben",
            enterNumberFrom: "Nummer eingeben von",
            enterNumberTo: "Nummer eingeben bis",
            enterDate: "Datum eingeben",
            enterInteger: "Ganzzahl eingeben",
            enterText: "Text eingeben",
            selectValue: "Wert auswählen",
            selectValues: "Werte auswählen"
        },
    },
    references: {
        Ref_AirType: "Lufttyp",
        Ref_AnalysisGroup: "Analysegruppe",
        Ref_AquiferStatus: "Aquiferenstatus",
        Ref_BiodiversityType: "Biodiversitätstyp",
        Ref_BoreholeStatus: "Bohrlochstatus",
        Ref_BoreholeType: "Bohrlochart",
        Ref_CapType: "Kappentyp",
        Ref_ConstructionType: "Konstruktionstyp",
        Ref_Context: "Kontext",
        Ref_DayNight: "Tag und Nacht",
        Ref_DrillingFluid: "Bohrflüssigkeit",
        Ref_DrillingMethod: "Bohrverfahren",
        Ref_EcologicalGroup: "Ökologische Gruppe",
        Ref_EcologicalIntegrity: "Ökologische Integrität",
        Ref_EmissionSource: "Emissionsquelle",
        Ref_EquipmentType: "Gerätetyp",
        Ref_FaunaUse: "Nutzung der Fauna",
        Ref_GeometryType: "Geometrietyp",
        Ref_HabitatType: "Lebensraumtyp",
        Ref_HumanUse: "Menschliche Nutzung",
        Ref_Installation: "Installation",
        Ref_IUCNStatus: "IUCN-Status",
        Ref_LithologyType: "Lithologie-Typ",
        Ref_Matrix: "Matrix",
        Ref_ObsMeasure: "Beobachtungsmaß",
        Ref_ObservationMode: "Beobachtungsmodus",
        Ref_ObservationType: "Beobachtungstyp",
        Ref_PhotoContext: "Fotokontext",
        Ref_Parameter: "Parameter",
        Ref_PiezometerGroup: "Piezometergruppe",
        Ref_PresenceProof: "Präsenznachweis",
        Ref_QAQCType: "QAQC-Typ",
        Ref_ReadingType: "Lesetyp",
        Ref_RecordPeriod: "Aufzeichnungszeitraum",
        Ref_RelativePosition: "Relative Position",
        Ref_ReleaseLocation: "Freigabeort",
        Ref_SampleCollectionType: "Probenentnahme-Typ",
        Ref_SampleType: "Probentyp",
        Ref_SensorType: "Sensortyp",
        Ref_StationBiodiversityType: "Stationsbiodiversität",
        Ref_StationSedimentWaterType: "Station Sedimentwasser",
        Ref_StratigraphicClassification: "Stratigraphische Klassifikation",
        Ref_TrophicGroup: "Trophische Gruppe",
        Ref_Unit: "Einheiten",
        Ref_Vulnerability: "Verwundbarkeit",
        Ref_WaterIntermittence: "Wasserintermittenz",
        Ref_WaterLocation: "Wasserstandort",
        Ref_WaterSource: "Wasserquelle",
        Ref_WaterType: "Wassertyp",
        Ref_WeightBasis: "Gewichtsgrundlage"
    },
};
export default DEU;