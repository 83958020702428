import SharedSettings from "../common/sharedSettings.json";

const POR = {
    common: {
        AccessWorkflow: "Acessar fluxo de trabalho",
        AccessStationsGrid: "Acessar a grade de estações",
        AccessStationsMap: "Acessar o mapa das estações",
        AccessZonesGrid: "Acessar a grade de zonas",
        AccessZonesMap: "Acessar o mapa das zonas",
        Actions: "Ações",
        Add: "Adicionar",
        AddAnElement: "Adicionar um elemento",
        AddSubEntity: "Adicionar Subentidade",
        Assign: "Atribuir um papel",
        AssignToInternalUsers: "Atribuir a usuários internos",
        AssignToExternalUsers: "Atribuir a usuários externos",
        AlertsOnly: "Apenas alertas",
        AlertsToSee: 
            "Há alguns alertas sobre este elemento. Mais informações na grade.",
        ApplicationDown: "Aplicativo está em manutenção no momento",
        ApplicationUnderMaintenance: "O aplicativo estará em manutenção de %{startDate} a %{endDate}",
        Back: "Voltar",
        Bookmarks: "Favoritos",
        Cancel: "Cancelar",
        CannotAccessThisPage: "Não é possível acessar esta página",
        Continue: "Continuar",
        ChangesHistory: "Histórico de alterações",
        CoordinateSystem: "Sistema de coordenadas",
        CreatingNew: "Criando um novo",
        DefineA: "Definir um",
        Delete: "Excluir",
        DeselectAll: "Desmarcar Todos",
        DontHaveAccessToAnyEntity: "Você atualmente não tem acesso a nenhuma entidade",
        Download: "Baixar",
        Edit: "Editar",
        Editing: "Editando o",
        AddingEnglish: "Adicionando valor em inglês para",
        EditEntityExtent: "Editar extensão da entidade",
        EditZone: "Editar zona",
        EditTreeVisibility: "Editar visibilidade da Árvore",
        ExcelExport: "Exportar para Excel",
        False: "Falso",
        FullName: "Nome Completo",
        GetResults: "Obter resultados",
        GINTExport: "Exportar para gINT",
        GridView: "Visualização em Grade",
        Group: "Grupo",
        Help: "Ajuda",
        Hidden: "Oculto",
        Loading: "Carregando...",
        Location: "Localização",
        Mandatory: "Obrigatório",
        MapView: "Visualização do Mapa",
        Move: "Mover",
        New: "Novo",
        NewFeaturesAreAdded: "Novos recursos foram adicionados",
        Next: "Próximo",
        NoAlerts: "Nenhum alerta para exibir",
        NoResults: "Nenhum resultado para exibir",
        OneTsigane: "Um Tsigane",
        OpenNotifications: "Abrir notificações",
        Overwrite: "Substituir",
        QuickSearchInto: "Pesquisa rápida em:",
        QuickSearchFilter: "Opções de filtro para pesquisa rápida",
        ParentFieldIsRequired: "O campo Pai é obrigatório.",
        PreviewFile: "Visualizar o arquivo",
        Private: "Privado",
        RedirectTo: "Redirecionar para:",
        Refresh: "Atualizar",
        ResultsFor: "Resultados para %{value} ",
        RemoveAccessRight: "Remover direitos de acesso",
        Search: "Pesquisar",
        SearchInTable: "Pesquisar na tabela",
        SeeAlerts: "Ver alertas",
        SeeMore: "Ver mais",
        Select: "Selecionar",
        SelectAll: "Selecionar Todos",
        SettingsPanel: "Painel de Configurações",
        Skip: "Pular",
        SkipUpload: "Pular upload",
        Close: "Fechar",
        Remove: "Remover",
        Request: "Solicitação",
        Role: "Papel",
        newRef: "Solicitar uma nova referência",
        addNewRef: "Solicitar uma nova referência",
        Save: "Salvar",
        Translate: "Traduzir",
        Tree: "Árvore",
        True: "Verdadeiro",
        Workflow: "Fluxo de Trabalho",
        GenerateAToken: "Gerar um Token",
        CopytoClipboard: "Copiar para a Área de Transferência",
        CopiedSuccessfully: "Copiado com Sucesso",
        SavedSuccessfully: "Salvo com Sucesso",
        featuresmessage: `Uma nova versão (versão ${SharedSettings.Version}) do aplicativo foi lançada. Ela inclui os seguintes novos recursos:`,
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} enviado com sucesso",
        NoFileToDownload: "Nenhum arquivo para baixar!",
        YouHaveAProblem: "Você tem um problema",
        ContactForm: "Formulário de contato",
        YouHaveAnIdea: "Você tem uma ideia",
        QuestionAboutTheApp: "Uma pergunta sobre o aplicativo",
        WantToTrackAProblem: "Quer rastrear um problema",
        QuestionAboutUsingTheToolWithBranches: "Uma pergunta sobre o uso da ferramenta com filiais",
        BranchManagers: "Gerentes de filial"
    },
    diagram: {
        Draft: "Rascunho",
        Failed: "Falhou",
        Final: "Final",
        Finalize: "Finalizar",
        ForValidation: "Para Validação",
        New: "Novo",
        NotValidated: "Não Validado",
        NotValid: "Não Válido",
        OK: "OK",
        Ok: "Ok",
        Processing: "Processando",
        Reject: "Rejeitar",
        Rejected: "Rejeitado",
        Remove: "Remover",
        Removed: "Removido",
        Requested: "Solicitado",
        Reset: "Redefinir",
        Rework: "Refazer",
        Reviewed: "Revisado",
        Submit: "Enviar",
        Submitted: "Enviado",
        UnderValidation: "Sob Validação",
        Validated: "Validado",
        Validate: "Validar",
        Valid: "Válido",
        Waiting: "Aguardando",
    },
    eddDataValidation: {
        Stations: "Estações",
        Samplings: "Amostragens",
        InSituUndergroundMeasObs: "Medições e Observações Subterrâneas In Situ",
        LaboResult: "Resultados do Laboratório",
        SpeciesIdentification: "Identificação de Espécies",
        EddValidationMayTakeSomeMinutes: "O carregamento pode levar alguns minutos, dependendo da quantidade de dados presentes no EDD."
    },
    enum: {
        unitCategories: {
            NONE: "Nenhum",
            CONDUCTANCE: "Condutância",
            CONDUCTIVITY: "Condutividade",
            FLAMMABILITY: "Inflamabilidade",
            HIGHT_DEPTH: "Altura/Profundidade",
            LENGTH: "Comprimento",
            MASS: "Massa",
            MASS_CONCENTRATIONS: "Concentrações de massa",
            MASS_FRACTIONS: "Frações de massa",
            MICROBIAL_CELLS_CONCENTRATION: "Concentração de células microbianas",
            PARTS_PER_MASS: "Partes por: Massa",
            PARTS_PER_VOLUME: "Partes por: Volume",
            PERCENTAGE: "Percentagem",
            PRESSURE: "Pressão",
            SPEED: "Velocidade",
            TEMPERATURE: "Temperatura",
            VOLTAGE: "Voltagem",
            VOLUME: "Volume",
            VOLUME_FLOW_RATE: "Taxa de fluxo de volume",
            WATER_HARDNESS: "Dureza da água",
            SPECIFIC_VOLUME: "Volume específico",
            SOUND_INTENSITY: "Intensidade sonora",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Taxa de desintegração radioativa",
            RADIOACTIVITY_PARTICLE_FLUX: "Fluxo de partículas radioativas"
        },
        frequencyQuery: {
            Monday: "Toda segunda-feira",
            Friday: "Toda sexta-feira",
            BeginningMonth: "Todo início de mês",
            EndMonth: "Todo final de mês",
        },
        rights: {
            COMPANYMANAGER: "Gerente de empresa",
            HOLDINGMANAGER: "Gerente de holding",
            ENTITYMANAGER: "Gerente de entidade",
            BRANCHMANAGER: "Gerente de filial",
            CONTRIBUTOR: "Contribuinte",
            READER: "Leitor",
            EXTERNAL: "Externo",
            GISMANAGER: "Gerente de GIS",
        },
    },
    field: {
        AdditionalExplanations: "Explicações adicionais",
        AnalysisGroup: "Grupo de Análise",
        Author: "Autor",
        Basemap: "Mapa base",
        Branch: "Filial",
        Cas: "Número CAS",
        Category: "Categoria",
        Code: "Código",
        Color: "Cor",
        Confidential: "Confidencial",
        Country: "País",
        CreatedOn: "Data de criação",
        CreatedByUser: "Criado por usuário",
        CreatedByUserDescription: "Identidade do usuário que criou o item",
        Description: "Descrição",
        DocumentCategory: "Categoria do Documento",
        EddFile: "Arquivo EDD",
        EndDate: "Data de término",
        Entity: "Entidade",
        EntityCode: "Código da entidade",
        EntityName: "Nome da entidade",
        External: "Externo",
        ExternalProvider: "Fornecedor Externo",
        Explaination: "Explicação",
        Family: "Família",
        File: "Arquivo",
        Filename: "Nome do arquivo",
        Firstname: "Primeiro nome",
        Frequency: "Frequência",
        GisManager: "Gerente de GIS",
        GroundAltitude: "Altitude do solo",
        InitialStudy: "Estudo inicial",
        IsPrimaryEDDFile: "Documento principal do EDD",
        Key: "Chave",
        Language: "Idioma",
        Lastname: "Último nome",
        LastUpdate: "Última atualização",
        LastUpdateDescription: "Data e hora da última atualização",
        LookUpTableName: "Nome da tabela de pesquisa",
        MapService: "Serviço de Mapa",
        MaxValue: "Valor máximo",
        MinValue: "Valor mínimo",
        Name: "Nome",
        No: "Não",
        NodeType: "Tipo de nó",
        Note: "Nota",
        Number: "Número",
        Observation: "Observação",
        Owner: "Proprietário",
        Parent: "Pai",
        Phone: "Telefone",
        PhotoContext: "Contexto da foto",
        PointCoordinates: "Coordenadas do ponto",
        PolygonCoordinates: "Coordenadas do polígono",
        PublicationDate: "Data de publicação",
        Public: "Público",
        QueryName: "Nome da consulta",
        Reference: "Referência",
        Region: "Região",
        RelatedZones: "Zonas relacionadas",
        RelatedStations: "Estações relacionadas",
        RelatedStationGroups: "Grupos de estações relacionados",
        RelatedStudies: "Estudos relacionados",
        RequestedAccess: "Acesso solicitado",
        RequestAccessType: "Tipo de solicitação de acesso",
        RequestDate: "Data da solicitação",
        RequestorIGG: "Solicitante IGG",
        RequestType: "Tipo de solicitação",
        ResultsSize: "Tamanho dos resultados",
        SamplingID: "ID da amostragem",
        SubSamplingID: "ID da subamostragem",
        SampleType: "Tipo de amostra",
        SecurityGroupType: "Tipo de Grupo de Segurança",
        Share: "Compartilhar",
        StartDate: "Data de início",
        StartEndDate: "Data de início / término",
        State: "Estado",
        Station: "Estação",
        StationAir: "Ar",
        StationBiodiversity: "Biodiversidade",
        StationCode: "Código da estação",
        StationGroups: "Grupos de estações",
        StationNoise: "Ruído",
        StationSedimentWater: "Água e Sedimento",
        StationSediment: "Sedimento",
        StationWater: "Água",
        StationSoilGroundwater: "Solo-Aquífero",
        StationType: "Tipo de Estação",
        Study: "Estudo",
        StudyType: "Tipo de Estudo",
        StudyYear: "Ano do Estudo",
        Submitter: "Submissor",
        Title: "Título",
        UpdatedByUser: "Atualizado por usuário",
        UpdatedByUserDescription: "Identidade do último usuário que atualizou o elemento",
        User: "Usuário",
        Units: "Unidades",
        Value: "Valor",
        Valid: "Válido",
        WorkOrder: "Ordem de Serviço",
        Yes: "Sim",
        Zones: "Zona",
        EmailNotif: "Receber notificações por e-mail",
        ExplanatoryText: "Token técnico que permite acesso às APIs do One-Tsigane. Este token é estritamente pessoal: Sua identidade será referenciada quando utilizado. É válido por 365 dias:",
    },
    grid: {
        title: {
            Administration: "Administração",
            Alerts: "Alertas",
            AllUsers: "Todos os Usuários",
            Analytes: "Analitos",
            Branches: "Filiais",
            BranchManagers: "Gerentes de Filial",
            CompanyManagers: "Gerentes de Empresa",
            HoldingManagers: "Gerentes de Holding",
            Configuration: "Configuração",
            UserPref: "Preferências do Usuário",
            Countries: "Países",
            Documents: "Documentos",
            EDDFiles: "Arquivos EDD",
            Entities: "Entidades",
            EntityManagers: "Gerentes de Entidade",
            EntityUsers: "Usuários da Entidade",
            ExternalUsers: "Usuários Externos",
            ExternalProviders: "Fornecedores Externos",
            Families: "Famílias",
            Files: "Arquivos",
            GISManagers: "Gerentes de GIS",
            InternalUsers: "Usuários Internos",
            LimitsOf: "Limites de",
            LookupHints: "Dicas de Pesquisa",
            NewFeaturesMessage: "Mensagem de Novos Recursos",
            PhotoVideo: "Fotos e Vídeos",
            Queries: "Consultas",
            Regions: "Regiões",
            Requests: "Solicitações para validar",
            MyRequests: "Minhas solicitações",
            ReportScheduler: "Agendador de Relatórios",
            Settings: "Configurações",
            Samplings: "Amostragens",
            Stations: "Estações",
            VisibilityEditor: "Editor de Visibilidade",
            WorkorderExternalUsers: `Gerenciar permissões de usuários externos no Estudo "%{value}", Ordem de Serviço "%{value2}"`,
            Zones: "Zonas",
        },
    },
    tree: {
        Add: "Adicionar nó filho a %{value}",
        AddBookmark: "Adicionar aos favoritos",
        AddStudy: "Adicionar um estudo a %{value}",
        AddStationGroup: "Adicionar um Grupo de Estações",
        Delete: "Excluir %{value}",
        Edit: "Editar %{value}",
        EditTranslation: "Editar traduções de %{value}",
        Entities: "Entidades",
        EntityTreeTemplate: "Modelo de Árvore de Entidades",
        EntityTreeTemplateEditor: "Editor de Modelo de Árvore de Entidades",
        Limits: "Limites",
        Lookups: "Pesquisas",
        Maps: "Mapas",
        References: "Referências",
        RemoveFromBookmarks: "Remover dos favoritos",
        Security: "Segurança",
        StationGroupNodeEmpty: 
            "Atualmente, não há nenhum grupo de estação para exibir. Clique aqui para criar um novo",
        StudyTemplate: "Modelo de Estudo",
        StudyTemplateEditor: "Editor de modelo de Estudo %{value}",
        Users: "Usuários",
        Requests: "Solicitações",
        StudyType: "Tipo de Estudo",
        TextType: "Tipo de Texto"
    },
    dialog: {
        Alerts: "Alertas",
        AssignTo: "Atribuir %{value} a um %{value2}",
        AddNewLimits: "Adicionar novos limites.",
        CopyZonesFromStudies: "Copiar zonas de outros estudos.",
        OverwriteFileConfirmation: 
            "Este arquivo já existe, você tem certeza de que deseja substituir o upload em andamento?",
        PromptChangesHasOccured: 
            "Tem certeza de que deseja navegar para fora desta página?\n\nVocê começou a escrever ou editar uma linha na grade de dados atual.\n\nPressione OK para continuar ou Cancelar para permanecer na página atual e confirmar suas alterações.",
        DeleteConfirmationRow: "Tem certeza de que deseja excluir esta linha?",
        DeleteConfirmationNode: "Tem certeza de que deseja excluir este nó?",
        Cancelled: "Cancelado",
        SessionVerification: 
            "Sua sessão está sendo verificada. Obrigado pela sua paciência.",
        UploadSkipped: "Upload pulado",
        NotificationDoneConfirmation: 
            "Tem certeza de que deseja validar esta notificação?",
    },
    input: {
        validation: {
            required: "Este campo é obrigatório.",
            DateRangeValidation: "A data de início deve ser antes da data de término",
            DropFileAccepted: "Solte os arquivos",
            DropFileRejected: `Desculpe, apenas ${SharedSettings.MaxFilesToUpload} arquivos são permitidos por vez`,
            NumberChar: "O número máximo de caracteres neste campo é: ",
            WrongDocumentExtension: `Esta é uma pasta de documentos: os arquivos aceitos não podem ser imagens ou vídeos, portanto o upload será rejeitado.`,
            WrongPhotoVideoExtension: `Esta é uma pasta de fotos/vídeos: os arquivos aceitos devem ser imagens ou vídeos, caso contrário, o upload será rejeitado.`,
            InvalidStartingDate: 
                "A data de início não pode ser após a data de término.",
            InvalidEndingDate: 
                "A data de término não pode ser antes da data de início.",
            InvalidRange: "O valor não corresponde ao intervalo",
            InvalidTime: "O horário inserido está incorreto. Por favor, verifique novamente. O intervalo de horário válido é de 00:00 a 23:59.",
            SelectStudy: "Por favor, selecione um estudo primeiro",
        },
    },
    languages: {
        Dutch: "Neerlandês",
        English: "Inglês",
        French: "Francês",
        German: "Alemão",
        Portuguese: "Português",
        Spanish: "Espanhol",
    },
    map: {
        addAPoint: "Adicionar um novo ponto",
        coordinates: "Coordenadas",
        errorSIG: 
            "Não foi possível estabelecer conexão com o SIG.",
        errorLayerSIG: 'Não foi possível estabelecer conexão com a camada SIG "%{layerName}".',
        entityOfInterest: "Entidade de interesse",
        extentOf: "Extensão de",
        latitude: "Latitude",
        longitude: "Longitude",
        mapServerLayers: "Camadas do servidor de mapa",
        point: "Ponto",
        polygon: "Polígono",
        removeAPoint: "Remover um ponto",
        redirectToStation: "Ver detalhes da estação",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Imagens",
            LAYER_PLAN: "Planos",
            LAYER_TOPO: "Topografias",
            LAYER_PIPELINES: "Oleodutos",
            LAYER_MISC: "Diversos",
            LAYER_CADASTRE: "Cadastros",
            LAYER_ZONE: "Zonas",
        },
        selectedStations: "Estações selecionadas",
        stationGroupsLayers: "Camadas de grupos de estações",
        stationsLayers: "Camadas de estações",
        stationsOf: "Estações de",
        stationsOfGroup: "Estações do grupo",
        zonesOf: "Zonas de",
        zonesExtents: "Extensões das zonas",
    },
    notification: {
        noNotification: "Você não tem notificações!",
        errorNotifictation: "As notificações não estão disponíveis no momento",
    },
    misc: {
        lambdaUser: "Usuário Lambda",
        From: "de:",
    },
    panel: {
        EditEntity: "Editar Entidade",
        NewEntity: "Nova Entidade",
        NewSubEntity: "Nova Subentidade",
    },
    placeholder: {
        Logout: "Sair",
        NoEntry: "Sem entrada",
        UserState: "Estado do Usuário",
        EddFileState: "Estado do arquivo EDD",
    },
    select: {
        Contributor: "Contribuinte",
        Reader: "Leitor",
        External: "Externo",
    },
    view: {
        entityPage: {
            Code: "Código",
            Name: "Nome",
            Country: "País",
            Branch: "Filial",
            Parent: "Pai",
            Contact: "Contatos",
            Documents: "Documentos",
            History: "Histórico",
            Home: "Início",
            Location: "Localização",
            Operations: "Operações",
            Photo: "Foto",
        },
        helpPage: {
            Contact: "Contato",
            Documents: "Documentos",
            InternalUsers: "Usuários Internos",
            ExternalUsers: "Usuários Externos",
            contact: {
                FileUploadedSuccessfully: "%{fileName} carregado com sucesso",
                NoFileToDownload: "Nenhum arquivo para baixar!",
                AnyTechnicalIssues: "Algum problema técnico",
                PleaseClickHere: "Por favor, clique aqui",
                AnyFurtherFunctionalities: "Gostaria de mais funcionalidades no sistema",
                PleaseReferToThisLink: "Por favor, consulte este link",
                QuestionAboutTheApp: "Uma pergunta sobre o aplicativo",
                WantToTrackAProblem: "Quer acompanhar um problema",
                ForSupportAtBranchLevel: "Para suporte ao nível de Gerente de Filial, entre em contato com",
            },
        },
        newUser: {
            CurrentRequests: "Solicitações atuais",
            EntityAccessRequest: "Solicitar acesso a uma entidade existente",
            EntityCreateRequest: "Solicitar a criação de uma nova entidade",
            Welcome: "Bem-vindo",
        },
        document: {
            AddMediaMetadata: "Adicionar metadados a:",
            AddDocument: "Adicionar documento",
            CancelMove: "Cancelar movimentação",
            CurrentStudy: "Estudo Atual:",
            Destination: "Destino",
            DragAndDropFile: `Arraste e solte um arquivo sobre a tela para carregar, ou use o botão 'Adicionar' na barra de ferramentas da grade de dados.`,
            Extension: "Extensão",
            FilesRejected: "Arquivos rejeitados.",
            HelpFileUpload: "Ajuda sobre o upload de arquivos",
            MaxFilesAllowed: `Uma ação de upload permite no máximo ${SharedSettings.MaxFilesToUpload} arquivos por vez.`,
            MoveDocument: "Mover um documento",
            OriginalStudy: "Estudo Original:",
            MoreInfo: "Mais informações",
            ListAllowedExtensions: "Lista de extensões de arquivo permitidas",
            SelectDestination: "Selecionar destino",
            UploadedBy: "Carregado por:",
        },
        eddFiles: {
            getTemplate: "Obter modelo de arquivo",
        },
        limit: {
            add: "Adicionar um novo limite",
            addNewLimits: "Adicionar novos limites",
            limitSelection: "Definição e Seleção de objeto",
            stepper: {
                CreateNewLimit: "Criar um novo limite",
                EditLimit: "Editar limite",
            },
            objectSelection: {
                object: "Objeto",
                parentObject: "Objeto pai",
            },
        },
        query: {
            ranOn: "Executado em",
            ranOnThe: "executado em",
            reporting: "Relatório",
            resultQuery: "Resultado da Consulta",
            runQuery: "Executar a consulta",
            seeResultsOnMap: "Ver esses resultados em um mapa",
            stepper: {
                CreateNewQuery: "Criar uma nova consulta",
                EditQuery: "Editar consulta",
            },
            objectSelection: {
                objectSelection: "Seleção de Objeto",
                object: "Objeto",
                parentObject: "Objeto pai",
                childObject: "Objeto filho",
                removeChild: "Remover filho",
            },
            conditions: {
                conditions: "Condições",
            },
            outputSelection: {
                outputSelection: "Seleção de Saída",
                Fieldname: "Nome do Campo",
                Label: "Rótulo",
                Description: "Descrição",
                Show: "Mostrar",
            },
        },
        request: {
            ADDUSER: "Adicionar um usuário",
            NEWENTITY: "Nova entidade",
            NEWREF: "Nova referência",
            OptionalObservation: "Observação opcional",
        },
        station: {
            CreateNewStation: "Criar nova estação",
            CreateStationGroup: "Criar um grupo de estações",
            EditStation: "Editar estação",
            StationGroup: "Grupo de estações",
            StationStationGroup: "Estações do grupo de estações:",
            StationGroupLoading: "Estações do grupo de estações: carregando...",
        },
        study: {
            Study: "o estudo",
            StudyWorkOrder: "Estudo %{value} : Ordens de Serviço",
            NoStudyWorkOrder: "Estudo ... : Ordens de Serviço",
        },
        workOrder: {
            EDDFiles: "Arquivos EDD",
            ExternalUsersPermissions: "Permissões de usuários externos",
        },
        zone: {
            CopyZones: "Copiar zonas",
            CopyZonesFromStudy: "Copiar zonas de outro estudo",
            CreateNewZone: "Criar nova zona para",
            EditExtent: "Editar a extensão de",
            EditZone: "Editar zona",
            ExtendDefined: "Extensão definida",
            ExtendNotDefined: "Extensão não definida",
            LoadingEntity: "Carregando entidade...",
            PreExistingZones: "Zonas pré-existentes de",
        },
    },
    packages: {
        colorPicker: {
            language: "POR",
            color: "cor",
            rgb: "rgb",
            R: "R",
            G: "G",
            B: "B",
            hsv: "hsv",
            H: "H",
            S: "S",
            V: "V",
            hsl: "hsl",
            WrongFormat: "Formato incorreto.",
            NotHexFormat: "Não é um valor hexadecimal.",
            transparent: "Transparente",
            none: "Inválido",
        },
        reactAwesomeQueryBuilder: {
            and: "e",
            or: "ou",
            valueLabel: "Valor",
            valuePlaceholder: "Valor",
            fieldLabel: "Campo",
            operatorLabel: "Operador",
            funcLabel: "Função",
            fieldPlaceholder: "Selecione o campo",
            funcPlaceholder: "Selecione a função",
            operatorPlaceholder: "Selecione o operador",
            lockLabel: "Travar",
            lockedLabel: "Trancado",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Adicionar grupo",
            addRuleLabel: "Adicionar regra",
            addSubRuleLabel: "Adicionar sub-regra",
            notLabel: "Não",
            valueSourcesPopupTitle: "Selecionar fonte de valor",
            removeRuleConfirmOptions: {
                title: "Tem certeza de que deseja excluir esta regra?",
                okText: "Sim",
                okType: "danger",
                cancelText: "Cancelar",
            },
            removeGroupConfirmOptions: {
                title: "Tem certeza de que deseja excluir este grupo?",
                okText: "Sim",
                okType: "danger",
                cancelText: "Cancelar",
            },
            like: "como",
            not_like: "não como",
            starts_with: "começa com",
            ends_with: "termina com",
            between: "entre",
            not_between: "não entre",
            is_null: "é nulo",
            is_not_null: "não é nulo",
            is_empty: "está vazio",
            is_not_empty: "não está vazio",
            select_any_in: "qualquer em",
            select_not_any_in: "não em",
            proximity: "proximidade",
            enterValue: "Digite o valor",
            enterNumber: "Digite o número",
            enterNumberFrom: "Digite o número de",
            enterNumberTo: "Digite o número até",
            enterDate: "Digite a data",
            enterInteger: "Digite o inteiro",
            enterText: "Digite o texto",
            selectValue: "Selecionar valor",
            selectValues: "Selecionar valores",
        },
    },
    references: {
        Ref_AirType: "Tipo de Ar",
        Ref_AnalysisGroup: "Grupo de Análise",
        Ref_AquiferStatus: "Status do Aquífero",
        Ref_BiodiversityType: "Tipo de Biodiversidade",
        Ref_BoreholeStatus: "Status do Furo",
        Ref_BoreholeType: "Tipo de Furo",
        Ref_CapType: "Tipo de Tampa",
        Ref_ConstructionType: "Tipo de Construção",
        Ref_Context: "Contexto",
        Ref_DayNight: "Dia e Noite",
        Ref_DrillingFluid: "Fluido de Perfuração",
        Ref_DrillingMethod: "Método de Perfuração",
        Ref_EcologicalGroup: "Grupo Ecológico",
        Ref_EcologicalIntegrity: "Integridade Ecológica",
        Ref_EmissionSource: "Fonte de Emissão",
        Ref_EquipmentType: "Tipo de Equipamento",
        Ref_FaunaUse: "Uso da Fauna",
        Ref_GeometryType: "Tipo de Geometria",
        Ref_HabitatType: "Tipo de Habitat",
        Ref_HumanUse: "Uso Humano",
        Ref_Installation: "Instalação",
        Ref_IUCNStatus: "Status da IUCN",
        Ref_LithologyType: "Tipo de Litologia",
        Ref_Matrix: "Matriz",
        Ref_ObsMeasure: "Medida de Observação",
        Ref_ObservationMode: "Modo de Observação",
        Ref_ObservationType: "Tipo de Observação",
        Ref_PhotoContext: "Contexto da Foto",
        Ref_Parameter: "Parâmetro",
        Ref_PiezometerGroup: "Grupo de Piezômetros",
        Ref_PresenceProof: "Prova de Presença",
        Ref_QAQCType: "Tipo de QAQC",
        Ref_ReadingType: "Tipo de Leitura",
        Ref_RecordPeriod: "Período de Registro",
        Ref_RelativePosition: "Posição Relativa",
        Ref_ReleaseLocation: "Local de Liberação",
        Ref_SampleCollectionType: "Tipo de Coleta de Amostras",
        Ref_SampleType: "Tipo de Amostra",
        Ref_SensorType: "Tipo de Sensor",
        Ref_StationBiodiversityType: "Biodiversidade da Estação",
        Ref_StationSedimentWaterType: "Tipo de Sedimento de Água da Estação",
        Ref_StratigraphicClassification: "Classificação Estratigráfica",
        Ref_TrophicGroup: "Grupo Trófico",
        Ref_Unit: "Unidades",
        Ref_Vulnerability: "Vulnerabilidade",
        Ref_WaterIntermittence: "Intermitência da Água",
        Ref_WaterLocation: "Localização da Água",
        Ref_WaterSource: "Fonte de Água",
        Ref_WaterType: "Tipo de Água",
        Ref_WeightBasis: "Base de Peso",
    },
};
export default POR;