import React, { useCallback } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import BranchGrid from "../components/DataGrid/BranchGrid";
import CountryGrid from "../components/DataGrid/CountryGrid";
import FamilyGrid from "../components/DataGrid/FamilyGrid";
import OTEntityGrid from "../components/DataGrid/OTEntityGrid";
import RegionGrid from "../components/DataGrid/RegionGrid";
import RequestStateDiagram from "../components/Diagrams/RequestStateDiagram";
import EntityCompleteEditionPanel from "../components/Views/Edition panels/EntityCompleteEditionPanel";
import EntityEditionPanel from "../components/Views/Edition panels/EntityEditionPanel";
import EntityWelcomeView from "../components/Views/EntityWelcomeView";
import { Box } from "@mui/material";
import * as r from "../js/constants/routes";
import { refRoutes } from "../js/constants/routes";
import EntityPageView from "../components/Views/EntityPageView";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import RequestIcon from "@mui/icons-material/AddTaskOutlined";
import CompanyManagerGrid from "../components/DataGrid/CompanyManagerGrid";
import HoldingManagerGrid from "../components/DataGrid/HoldingManagerGrid";
import BranchManagerGrid from "../components/DataGrid/BranchManagerGrid";
import ReferencesDataGrid from "../components/DataGrid/ReferencesDataGrid";
import EntityManagerGrid from "../components/DataGrid/EntityManagerGrid";
import RequestGrid from "../components/DataGrid/RequestsGrid";
import ExternalUsersGrid from "../components/DataGrid/ExternalUsersGrid";
import InternalUsersGrid from "../components/DataGrid/InternalUsersGrid";
import AllUsersGrid from "../components/DataGrid/AllUsersGrid";
import EntityUserGrid from "../components/DataGrid/EntityUserGrid";
import BulkUploadGrid from "../components/DataGrid/BulkUploadGrid";
import { useSelector } from "react-redux";
import GISManagerGrid from "../components/DataGrid/GISManagerGrid";
import ExternalProviderGrid from "../components/DataGrid/ExternalProviderGrid";
import LookupHintGrid from "../components/DataGrid/LookupHintGrid";
import { EntityMapEditor } from "../components/Views/Edition panels/EntityMapEditor";
import TreeTemplateEditor, {
    EntityTemplateForm,
    StudyTreeTemplateEditor,
} from "../components/TreeEditors/TreeTemplateEditor";
import EntityTree from "../components/TreeEditors/EntityTree";
import WorkOrderGrid from "../components/DataGrid/WorkOrderGrid";
import UserStateDiagram from "../components/Diagrams/UserStateDiagram";
import DocumentsView from "../components/Views/DocumentsView";
import EntityTreeVisibilityEditor from "../components/TreeEditors/EntityTreeVisibilityEditor";
import StationMap from "../components/ArcGIS/StationsMap";
import StationGroupGrid from "../components/DataGrid/StationGroupGrid";
import ZoneGrid from "../components/DataGrid/ZoneGrid";
import ZoneEditionPanel from "../components/Views/Edition panels/ZoneEditionPanel";
import { t, Translate } from "react-i18nify";
import QueryGrid from "../components/DataGrid/QueryGrid";
import GenericDataGrid from "../components/Views/GenericDataGrid/GenericDataGrid";
import EditionPanelQueryStepper from "../components/Views/QueryBuilder/EditionPanelQueryStepper";
import QuickSearchResultsTabs from "../components/Widgets/Tabs";
import EDDFileGrid from "../components/DataGrid/EDDFileGrid";
import EddFileDiagram from "../components/Diagrams/EddFileDiagram";
import { uuidv4 } from "../js/redux/actions/syncTab";
import ExternalUserWorkOrderGrid from "../components/DataGrid/ExternalUserWorkOrderGrid";
import AnalyteGrid from "../components/DataGrid/AnalyteGrid";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import LimitGrid from "../components/DataGrid/LimitGrid";
import EditionPanelLimitStepper from "../components/Views/LimitBuilder/EditionPanelLimitStepper";
import ActiveLimitGrid from "../components/DataGrid/ActiveLimitGrid";
import QueryResultsMap from "../components/ArcGIS/QueryResultsMap";
import ReportSchedulerGrid from "../components/DataGrid/ReportSchedulerGrid";
import UserRequestsGrid from "../components/DataGrid/UserRequestsGrid";
import DocumentDiagram from "../components/Diagrams/DocumentDiagram";
import UserPrefEditionPanel from "../components/Views/Edition panels/UserPrefEditionPanel";
import HelpPageView from "../components/Views/HelpPageView";
import NewFeaturesMessage from "../components/Views/Edition panels/NewFeaturesMessage";

export const randomID = () => uuidv4();

/** Paths where useRouteMatch will evaluate to extract nodeID. Be aware  */
export const possibleNodePaths = [
    "/zipFileNode/:nodeID/",
    "/documentNode/:nodeID/",
    "/datagrid/station-map/:nodeID",
    "/datagrid/zone/:Parent/:nodeID",
    "/datagrid/OTEntity/:otEntityID/:elementType/:nodeID", // TODO : need to handle StationGroup nodes, cf. Zoho Issue n°992
    "/datagrid/:GenericGridTypeParent/:nodeID",
];
export const dataGrid = `/datagrid`;
export const managementGrid = `/management-datagrid`;
export const history = `/history`;
export const treeEditor = "/treeEditor";

export const requestDiagramRoute = "/request-diagram";
export const eddFileDiagramRoute = "/edd-file-diagram";
export const stationMapRoute = `${dataGrid}/station-map`;
export const stationRoute = `${dataGrid}${r.station.route}`;
export const zoneEntityRoute = `${dataGrid}${r.zone.route}/entity`;
export const zoneStudyRoute = `${dataGrid}${r.zone.route}/study`;
export const userDiagramRoute = "/user-diagram";
export const documentDiagramRoute = "/document-diagram";

export const queryViewReporting = "/query-view-reporting";

export const entitiesNode = "ENTITIES";
export const menuRoutes = [
    { label: "/home", route: "/home", component: <EntityWelcomeView /> },
    {
        label: "Entity page",
        route: "/entity-page",
        component: <EntityWelcomeView />,
    },
    {
        label: "Diagram",
        route: requestDiagramRoute,
        component: <RequestStateDiagram />,
    },
    {
        label: "Diagram",
        route: eddFileDiagramRoute,
        component: <EddFileDiagram />,
    },
    {
        route: `${treeEditor}${r.studyTreeTemplate}/:elementID`,
        component: <StudyTreeTemplateEditor />,
    },
    {
        route: `${dataGrid}${r.study}/:elementID/:linkOrigin/OTEntityID/:otEntityID` /* link origin is either 'fromNode', or 'redirection'. For displaying <Back> button purposes  */,
        component: <WorkOrderGrid />,
    },
    {
        route: `${dataGrid}${r.oTEntity}/:OTEntityID${r.StationGroup}/:stationGroupID`,
        component: <StationGroupGrid />,
    },
    {
        route: `${zoneEntityRoute}/:NodeID/:searchInput?`,
        component: (
            <ZoneGrid
                baseURL={r.zone.route}
                label={r.zone.label}
                typeZone="entity"
            />
        ),
    },
    {
        route: `${zoneStudyRoute}/:NodeID/:searchInput?`,
        component: (
            <ZoneGrid
                baseURL={r.zone.route}
                label={r.zone.label}
                typeZone="study"
            />
        ),
    },
    {
        route: `${dataGrid}${r.eddFile.route}/:NodeID/OTEntityID/:OTEntityID`,
        component: (
            <EDDFileGrid baseURL={r.eddFile.route} label={r.eddFile.label} />
        ),
    },
    {
        route: `/zipFileNode/:nodeID`,
        component: (
            <BulkUploadGrid baseURL={r.bulkUpload.route} />
        ),
    },
    {
        route: `${dataGrid}${r.limits}/:NodeID/OTEntityID/:OTEntityID`,
        component: <ActiveLimitGrid baseURL={r.activeLimit.route} />,
    },
    {
        route: `${r.eddFile.route}/WorkOrder/:WorkOrderID/OTEntityID/:OTEntityID`,
        component: (
            <EDDFileGrid
                baseURL={r.eddFile.route}
                label={r.eddFile.label}
                fromWorkOrder={true}
            />
        ),
    },
    {
        route: `${dataGrid}/WorkOrder/:WorkOrderID${r.eddFile.route}`,
        component: (
            <EDDFileGrid
                baseURL={r.eddFile.route}
                label={r.eddFile.label}
                fromWorkOrder={true}
            />
        ),
    },
    {
        // This grid for managing external user permissions on a study.
        route: `${dataGrid}/WorkOrder/:WorkOrderID${r.externalUser}`,
        component: (
            <ExternalUserWorkOrderGrid baseURL={r.externalUserWorkOrder} />
        ),
    },
];

const entityTreeRouteSwitch = node => {
    switch (node.NodeType) {
        case "OTEntityTreeTemplateStationNodeType":
            return "";
        case "SamplingsNodeType":
            return "";
        case "OTEntityTreeTemplateStationMapNodeType":
            return `${stationMapRoute}/${node.ID}/OTEntityID/${node.OTEntityID}`;
        case "StudyNodeType":
            return `${dataGrid}${r.study}/${node.TemplateReferenceID}/fromNode/OTEntityID/${node.OTEntityID}`;
        case "StationGroupNodeType":
            return `${dataGrid}${r.oTEntity}/${node.OTEntityID}${r.StationGroup}/${node.ID}`;
        case "OTEntityTreeTemplateZoneNodeType":
            return `${zoneEntityRoute}/${node?.ID}`;
        case "StudyTreeTemplateZoneNodeType":
            return `${zoneStudyRoute}/${node?.ID}`;
        case "OTEntityTreeTemplateStudyNode":
            return "";
        case "OTEntityTreeTemplateStationGroupNodeType":
            return "";
        case "OTEntityTreeTemplateStudyNodeType":
            return "";
        case "EDDFile":
            return "";
        case "OTEntityTreeTemplateEddNodeType":
            return `${dataGrid}${r.eddFile.route}/${node.ID}/OTEntityID/${node.OTEntityID}`;
        case "OTEntityTreeTemplateLimitNodeType":
            return `${dataGrid}${r.limits}/${node.ID}/OTEntityID/${node.OTEntityID}`;
        case "GenericGrid":
            return `${dataGrid}/${node.GenericGridType}/${node.ID}/root/OTEntityID/${node.OTEntityID}`;
        case "EDDGenericGrid":
            return `${dataGrid}/${node.Name}/${node.OTEntityID}/root/EDDFileID/${node.EDDFileID}`;
        case "OTEntityTreeTemplateBulkUploadNodeType":
            return `/zipFileNode/${node?.ID}`;
        default:
            return `/documentNode/${node?.ID}/${node.HasDocuments}`;
    }
};
const entityTreeLoadingRouteSwitch = (node, onlyTemplate) => {
    switch (node.NodeType) {
        case "OTEntityTreeTemplateStationGroupNodeType":
            return `${r.StationGroup}/GetStationGroups/${node.OTEntityID}`;
        case "OTEntityTreeTemplateEddNodeType":
            return `${r.eddFile.route}/select/detail?filter[OTEntityID]=${node.OTEntityID}&filter[State]=Submitted`;
        case "EDDFile":
            return `${r.eddGrids}/${node.StudyType}`;
        default:
            return `${r.oTEntityTreeGet}/${node.OTEntityID}/${node.ID}?filter[getOnlyTemplates]=${onlyTemplate}`;
    }
};

/** Returns the node configuration, depending of the NodeType of the node.  */
export const entityTreeDataMapperSwitch = (
    node,
    hasAccessToBulkUpload,
    other,
    onlyTemplate = false,
    show = false
) => {
    switch (node.NodeType) {
        case "OTEntityTreeTemplateStationGroupNodeType":
            return entityTreeDataMapper(node, {
                dataMapper: e => {
                    return e.StationGroup_select.map(
                        s =>
                            entityTreeDataMapperSwitch(
                                {
                                    CanEdit: e.CanEdit,
                                    CanAddNode: false,
                                    CanDeleteNode: e.CanDeleteNode,
                                    ...s,
                                    ID: s.ObjectID,
                                    NodeType: "StationGroupNodeType",
                                    OTEntityID: node.OTEntityID,
                                    parentNode: node,
                                },
                                other,
                                onlyTemplate,
                                show
                            ),
                        false,
                        show
                    );
                },
            });
        case "OTEntityTreeTemplateEddNodeType":
            return entityTreeDataMapper(
                node,
                {
                    dataMapper: e =>
                        entityTreeDataMapperSwitch(
                            {
                                ...e,
                                NodeType: "EDDFile",
                                OTEntityID: node.OTEntityID,
                                HasChildren: true,
                                AllChildrenHidden: false,
                                ID: e.ObjectID,
                            },
                            other,
                            onlyTemplate,
                            show
                        ),
                },
                false,
                show
            );
        case "EDDFile":
            return entityTreeDataMapper(
                node,
                {
                    dataMapper: e => {
                        return e.Entities.map(grid =>
                            entityTreeDataMapperSwitch(
                                {
                                    ...grid,
                                    NodeType: "EDDGenericGrid",
                                    OTEntityID: node.OTEntityID,
                                    StudyType: node.StudyType,
                                    ID: `${node.ObjectID}-${grid.Name}`,
                                    EDDFileID: node.ObjectID,
                                },
                                other,
                                onlyTemplate,
                                show
                            )
                        );
                    },
                },
                false,
                show
            );
        case "OTEntityTreeTemplateBulkUploadNodeType":
            return hasAccessToBulkUpload ? entityTreeDataMapper(node, other, onlyTemplate, show) : null
        default:
            return entityTreeDataMapper(node, other, onlyTemplate, show);
    }
};

const treeLabelSwitch = node => {
    if (node.NodeType === "EDDGenericGrid") {
        return `${node.DetailedName}`;
    }

    return (node.SumOfDocuments===null || node.NodeType==='EDDFile') ? node.Value : node.Value + ' (' + node.SumOfDocuments+')';
};

/** Mapping function to generate and complete data for each node in the entity tree, regading the given node type. DOC*/
const entityTreeDataMapper = (e, other, onlyTemplate, show = false) => {
    return (e.Hidden && !show) || e.NodeType === "DocumentNodeType"
        ? null
        : {
              ...e,
              route: entityTreeRouteSwitch(e),
              label: treeLabelSwitch(e),
              loadingRoute:
                  e.HasChildren && (show || !e.AllChildrenHidden)
                      ? entityTreeLoadingRouteSwitch(e, onlyTemplate)
                      : null,
              dataMapper: node =>
                  entityTreeDataMapperSwitch(node, other, onlyTemplate, show),
              ...other,
          };
};

/** This set of nodes are provided by a single configuration JSON, for References routes in menuTree, !! not dynamic notes !! DOC*/
export const refRoutesNodes = () => {
    let sortingRoutes = refRoutes.sort((a, b) =>
        a.route.localeCompare(b.route)
    );
    return Object.values(sortingRoutes).map(item => {
        return {
            ID: `${dataGrid}${item.route}`,
            key: `${dataGrid}${item.route}`,
            label: t(item.label),
            route: `${dataGrid}${item.route}`,
            component: (
                <ReferencesDataGrid
                    label={t(item.label)}
                    baseURL={item.route}
                    readOnly={item.readOnly}
                    specificColumns={item.specificColumns}
                />
            ),
            parentNode: `${t("tree.References")} – ${t("tree.Lookups")}`,
        };
    });
};

/** Array, main left menu tree definition, which is also rendering the entities and their nodes  DOC*/
export const useMenuTree = entities => {
    const {
        EntityManagerEntityIDs,
        ReaderEntityIDs,
        ContributorEntityIDs,
        CompanyManager,
        HoldingManager,
        BranchManager,
        Contributor,
        Reader
    } = useSelector(state => state.auth);
    const hasRight = enityID =>
        CompanyManager ||
        HoldingManager ||
        EntityManagerEntityIDs?.includes(enityID) ||
        ReaderEntityIDs?.includes(enityID) ||
        ContributorEntityIDs?.includes(enityID);

    let hasAccessToBulkUpload = !BranchManager && !Contributor && !Reader;

    return [
        {
            ID: randomID(),
            label: <Translate value={"view.entityPage.Home"} />,
            route: "/home",
            component: <EntityWelcomeView />,
            Icon: HomeOutlinedIcon,
        },
        {
            ID: randomID(),
            label: <Translate value={"tree.Requests"} />,
            route: "/requests",
            component: <RequestGrid />,
            Icon: RequestIcon,

            children: [
                {
                    ID: randomID(),
                    label: <Translate value={"grid.title.Requests"} />,
                    route: `${managementGrid}${r.request}/tovalidate`,
                    component: <RequestGrid />,
                    parentNode: t("grid.title.Requests"),
                    access: {
                        BranchManager: true,
                        EntityManager: true,
                    },
                },
                {
                    ID: randomID(),
                    label: <Translate value={"grid.title.MyRequests"} />,
                    route: `${managementGrid}${r.request}/myrequests`,
                    component: <UserRequestsGrid />,
                    parentNode: t("grid.title.Requests"),
                },
            ],
        },
        {
            ID: randomID(),
            label: <Translate value={"grid.title.Limits"} />,
            Icon: TuneOutlinedIcon,
            route: r.limits,
            component: (
                <LimitGrid label={r.limit.label} baseURL={r.limit.route} />
            ),
            access: {
                BranchManager: true,
                EntityManager: true,
                Contributor: true,
                Reader: false,
            },
        },
        {
            ID: randomID(),
            label: <Translate value={"grid.title.Queries"} />,
            Icon: DataThresholdingOutlinedIcon,
            access: {
                BranchManager: false,
                EntityManager: true,
                Contributor: true,
                Reader: false,
            },
            children: [
                {
                    ID: randomID(),
                    label: <Translate value={"grid.title.Queries"} />,
                    route: r.queries,
                    component: (
                        <QueryGrid
                            label={r.query.label}
                            baseURL={r.query.route}
                        />
                    ),
                    parentNode: t("grid.title.Queries"),
                },
                {
                    ID: randomID(),
                    label: <Translate value={"grid.title.ReportScheduler"} />,
                    route: `${dataGrid}${r.reportScheduler.route}`,
                    component: (
                        <ReportSchedulerGrid
                            label={r.reportScheduler.label}
                            baseURL={r.reportScheduler.route}
                        />
                    ),
                    parentNode: t("grid.title.Queries"),
                },
            ],
        },
        {
            ID: entitiesNode,
            label: <Translate value={"tree.Entities"} />,
            Icon: FactoryOutlinedIcon,
            children: entities.map(e => {
                return {
                    ID: e.ObjectID,
                    label: e.Name,
                    code: e.Code,
                    OTEntityID: e.ObjectID,
                    route: `/entity-page/${e.ObjectID}`,
                    loadingRoute: hasRight(e.ObjectID)
                        ? `${r.oTEntityTreeGet}/${e.ObjectID}`
                        : null,
                    hasContextMenu: false,
                    dataMapper: node => entityTreeDataMapperSwitch(node, hasAccessToBulkUpload),
                };
            }),
            CustomTreeView: EntityTree,
        },
        {
            ID: "References",
            nodeID: "References",
            label: <Translate value={"tree.References"} />,
            Icon: TableRowsOutlinedIcon,
            children: [
                {
                    ID: `${dataGrid}${r.analytes.route}`,
                    nodeID: `${r.analytes.route}`,
                    label: t("gird.title.Analytes"),
                    route: `${dataGrid}${r.analytes.route}`,
                    component: (
                        <AnalyteGrid
                            baseURL={r.analytes.route}
                            label={r.analytes.label}
                        />
                    ),
                    parentNode: t("tree.References"),
                },
                {
                    ID: `${dataGrid}${r.region.route}`,
                    nodeID: `${r.region.route}`,

                    label: t("field.Region"),
                    route: `${dataGrid}${r.region.route}`,
                    component: (
                        <RegionGrid
                            baseURL={r.region.route}
                            label={r.region.label}
                        />
                    ),
                    parentNode: t("tree.References"),
                },
                {
                    ID: `${dataGrid}${r.family.route}`,
                    nodeID: `${r.family.route}`,
                    label: t(`field.Family`),
                    route: `${dataGrid}${r.family.route}`,
                    component: (
                        <FamilyGrid
                            baseURL={r.family.route}
                            label={r.family.label}
                        />
                    ),
                    parentNode: t("tree.References"),
                },
                {
                    ID: `${dataGrid}${r.branch.route}`,
                    nodeID: `${r.branch.route}`,
                    label: t("field.Branch"),
                    route: `${dataGrid}${r.branch.route}`,
                    component: (
                        <BranchGrid
                            baseURL={r.branch.route}
                            label={r.branch.label}
                            paramURL={r.filter.allFilter}
                        />
                    ),
                    parentNode: t("tree.References"),
                },
                {
                    ID: `${dataGrid}${r.country.route}`,
                    nodeID: `${r.country.route}`,
                    label: t("field.Country"),
                    route: `${dataGrid}${r.country.route}`,
                    component: (
                        <CountryGrid
                            baseURL={r.country.route}
                            label={r.country.label}
                        />
                    ),
                    parentNode: t("tree.References"),
                },
                {
                    ID: "Lookups",
                    nodeID: "Lookups",
                    label: t("tree.Lookups"),
                    Icon: null,
                    children: refRoutesNodes(),
                    parentNode: t("tree.References"),
                },
            ],
        },
    ];
};

/** Definition of all the nodes and branches regarding the right menu, speaking of the Settings/Configuration drawer*/
export const menuPrefs = [
    {
        ID: randomID(),
        label: <Translate value="grid.title.Configuration" />,
        access: {
            BranchManager: true,
            EntityManager: true,
        },
        children: [
            {
                ID: randomID(),
                label: <Translate value="grid.title.Administration" />,
                access: {
                    BranchManager: false,
                    EntityManager: false,
                },
                children: [
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.NewFeaturesMessage" />,
                        route: `${r.newFeaturesMessage}`,
                        component: <NewFeaturesMessage baseURL={r.newFeaturesMessage} />,
                        access: {
                            HoldingManager: false,
							Contributor: false,
                            Reader: false,
                        },
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.LookupHints" />,
                        route: `${dataGrid}${r.lookupHint}`,
                        component: <LookupHintGrid baseURL={r.lookupHint} />,
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="tree.EntityTreeTemplate" />,
                        route: `${treeEditor}${r.oTEntityTreeTemplate}`,
                        component: (
                            <TreeTemplateEditor
                                getLabel={() => (
                                    <Translate value="tree.EntityTreeTemplateEditor" />
                                )}
                                baseURL={r.oTEntityTreeTemplate}
                                getURL={r.oTEntityTreeTemplateEditor}
                                Form={EntityTemplateForm}
                            />
                        ),
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="tree.StudyTemplate" />,
                        loadingRoute: `${r.studyType}/select`,
                        dataMapper: e => ({
                            route: `${treeEditor}${r.studyTreeTemplate}/${e.ObjectID}`,
                            ID: e.ObjectID,
                            label: e.Value,
                        }),
                    },
                ],
            },
            {
                ID: randomID(),
                label: <Translate value="tree.Security" />,
                children: [
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.CompanyManagers" />,
                        access: {
                            BranchManager: false,
                            EntityManager: false,
                        },
                        route: `${dataGrid}${r.companyManager}`,
                        component: (
                            <CompanyManagerGrid baseURL={r.companyManager} />
                        ),
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.HoldingManagers" />,
                        access: {
                            BranchManager: false,
                            EntityManager: false,
                        },
                        route: `${dataGrid}${r.holdingManager}`,
                        component: (
                            <HoldingManagerGrid baseURL={r.holdingManager} />
                        ),
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.BranchManagers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: false,
                        },
                        route: `${dataGrid}${r.branchManager}`,
                        component: (
                            <BranchManagerGrid baseURL={r.branchManager} />
                        ),
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.EntityManagers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.entityManager}`,
                        component: (
                            <EntityManagerGrid baseURL={r.entityManager} />
                        ),
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.GISManagers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.gisManager}`,
                        component: <GISManagerGrid baseURL={r.gisManager} />,
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.EntityUsers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.entityUser}`,
                        component: <EntityUserGrid baseURL={r.entityUser} />,
                    },
                ],
            },
            {
                ID: randomID(),
                label: <Translate value="tree.Users" />,
                access: {
                    BranchManager: true,
                    EntityManager: true,
                },
                children: [
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.InternalUsers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.internalUser}`,
                        component: <InternalUsersGrid baseURL={r.oTUser} />,
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.ExternalUsers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.externalUser}`,
                        component: <ExternalUsersGrid baseURL={r.oTUser} />,
                    },
                    {
                        ID: randomID(),
                        label: <Translate value="grid.title.AllUsers" />,
                        access: {
                            BranchManager: true,
                            EntityManager: true,
                        },
                        route: `${dataGrid}${r.allUser}`,
                        component: <AllUsersGrid baseURL={r.oTUser} />,
                    },
                ],
            },
            {
                ID: randomID(),
                label: <Translate value="grid.title.Entities" />,
                route: `${dataGrid}${r.oTEntity}`,
                access: {
                    BranchManager: true,
                    EntityManager: true,
                },
                component: <OTEntityGrid baseURL={r.oTEntity} />,
            },
            {
                ID: randomID(),
                label: <Translate value="grid.title.ExternalProviders" />,
                route: `${dataGrid}${r.externalProvider}`,
                access: {
                    BranchManager: true,
                    EntityManager: true,
                },
                component: (
                    <ExternalProviderGrid baseURL={r.externalProvider} />
                ),
            },
        ],
    },
    {
        ID: randomID(),
        label: <Translate value="grid.title.UserPref" />,
        route: `/userPrefs/edit`,
    },
];

/** Helper used for checkAccess() function, and determines permission based on the user right and requirement of the route. DOC*/
export const haveAccess = (auth, routeAccess) => {
    if (routeAccess === undefined) {
        return true;
    } else {
        const userRight = Object.keys(auth).filter(k => auth[k]);
        const access = {
            CompanyManager: true,
            HoldingManager: routeAccess.HoldingManager===false ? routeAccess.HoldingManager : true ,
            BranchManager: routeAccess.BranchManager,
            EntityManager: routeAccess.EntityManager,
            Contributor: routeAccess.Contributor,
            Reader: routeAccess.Reader,
        };
        return userRight.reduce((acc, elem) => acc || access[elem], false);
    }
};

/** We'll establish if the user has access to the node, regarding his rights and if the node requires a specific permission. DOC*/
export function checkAccess(subMenu, auth) {
    let subMenuFiltred = [];
    subMenu.forEach(element => {
        if (haveAccess(auth, element.access)) {
            if (element.children) {
                const filtredChildren = checkAccess(element.children, auth);
                return subMenuFiltred.push({
                    ...element,
                    children: filtredChildren,
                });
            }

            return subMenuFiltred.push(element);
        } else {
            if (element.children) {
                const filtredChildren = checkAccess(element.children, auth);
                if (filtredChildren.length > 0) {
                    return {
                        ...element,
                        route: undefined,
                        component: undefined,
                        children: filtredChildren,
                    };
                }
            }

            return null;
        }
    });

    return subMenuFiltred;
}

const TreeRoutes = ({ menu, auth }) => {
    const GenerateRoute = (data = []) => {
        return data.map(e => {
            return (
                <Box key={randomID()}>
                    {e.route && (
                        <Route
                            exact
                            key={e}
                            path={e.route}
                            render={() => e.component}
                        />
                    )}
                    {e.children && GenerateRoute(e.children)}
                </Box>
            );
        });
    };

    return GenerateRoute(checkAccess(menu, auth));
};

const MenuRouter = () => {
    const {
        CompanyManager,
        HoldingManager,
        BranchManager,
        EntityManager,
        Contributor,
        Reader,
    } = useSelector(state => state.auth);
    const internalMenuTree = useMenuTree([]);
    const TreeRoutesMemo = useCallback(
        () =>
            TreeRoutes({
                menu: [...internalMenuTree, ...menuPrefs],
                auth: {
                    CompanyManager,
                    HoldingManager,
                    BranchManager,
                    EntityManager,
                    Contributor,
                    Reader,
                },
            }),
        [
            CompanyManager,
            HoldingManager,
            BranchManager,
            EntityManager,
            Contributor,
            Reader,
            internalMenuTree,
        ]
    );

    return (
        <Switch>
            <Route
                path="/newEntityRequest/:mode/:UrlID"
                render={() => <EntityCompleteEditionPanel viaRequest />}
            />
            <Route
                path="/entity/:mode/:UrlID"
                render={() => <EntityCompleteEditionPanel />}
            />
            <Route
                path="/entity-page/:mode/:UrlID"
                render={() => <EntityEditionPanel />}
            />
            <Route
                path="/home/:mode/:UrlID"
                render={() => <EntityEditionPanel />}
            />
            {/* <Route
                path={`${stationRoute}/:OTEntityID/:mode/:UrlID`}
                render={() => <StationEditionPanel />}
            /> */}
            <Route
                path={`${dataGrid}${r.zone.route}/:Parent/:NodeID/:mode/:UrlID`}
                render={() => <ZoneEditionPanel />}
            />
            <Route
                path="/query/:UrlID/queryResultsMap"
                render={() => (
                    <QueryResultsMap
                        title={r.query.label}
                        baseURL={r.queryResult + "/GetStationsQueryResult"}
                    />
                )}
            />
            <Route
                path="/query/:mode/:UrlID"
                render={() => <EditionPanelQueryStepper />}
            />
            <Route
                path="/limit/:mode/:UrlID"
                render={() => <EditionPanelLimitStepper />}
            />
            <Route
                path="/entity-page/:UrlID"
                render={() => <EntityPageView />}
            />

            {/* Diagrams display routes */}
            <Route
                path={requestDiagramRoute}
                render={() => <RequestStateDiagram />}
            />
            <Route
                path={userDiagramRoute}
                render={() => <UserStateDiagram />}
            />

            <Route
                path={documentDiagramRoute}
                render={() => <DocumentDiagram />}
            />
            <Route
                path="/entity-map-editor/:UrlID"
                render={() => (
                    <EntityMapEditor title={t("view.zone.EditExtent")} />
                )}
            />
            <Route
                path="/entity-tree-visibility-editor/:elementID"
                render={() => <EntityTreeVisibilityEditor />}
            />
            <Route
                path={`${stationMapRoute}/:nodeID/OTEntityID/:OTEntityID`}
                render={() => <StationMap />}
            />
            <Route
                path={`/userPrefs/:mode`}
                render={() => <UserPrefEditionPanel />}
            />

            {/* MEMO: Temporary routes, rendering sample component */}
            {menuRoutes.map((mr, index) => {
                return (
                    <Route
                        exact
                        key={index + "subroute"}
                        path={mr.route}
                        render={() => mr.component}
                    />
                );
            })}

            {/* Document node generic route */}
            <Route
                path="/documentNode/:nodeID/:hasDocuments/:searchInput?"
                render={r => <DocumentsView key={r.location.key} />}
            />
            <Route
                path={`${history}/:historyItem/documentNode/:nodeID/:hasDocuments/:searchInput?`} // subgrid displaying the viewer of history changes
                render={r => (
                    <DocumentsView
                        key={r.location.key}
                        isHistoryViewer={true}
                    />
                )}
            />
            <Route
                path={`${dataGrid}/:GenericGridTypeParent/:nodeID/:GenericGridTypeChild/:element/:elementID/EDDFileID/:EDDFileID`} // subgrid related to station (lithology, etc)
                render={r => <GenericDataGrid key={r.location.key} />}
            />

            <Route
                path={`${dataGrid}/:GenericGridTypeParent/:nodeID/:GenericGridTypeChild/:element/:elementID/:searchInput?`} // subgrid related to station (lithology, etc)
                render={r => <GenericDataGrid key={r.location.key} />}
            />
            <Route
                path={`${history}/:historyItem${dataGrid}/:GenericGridTypeParent/:nodeID/:GenericGridTypeChild/:element/:elementID`} // subgrid displaying the viewer of history changes
                render={r => (
                    <GenericDataGrid
                        key={r.location.key}
                        isHistoryViewer={true}
                    />
                )}
            />
            <Route
                path={`/searchResults/:searchInput/:randomID`}
                render={() => <QuickSearchResultsTabs />}
            />
            <Route
                path={`/help`}
                render={() => <HelpPageView />}
            />
            {/* Document station specific route */}
            <Route
                path={`${history}/:historyItem/:fileType/:element/:elementID/:hasDocuments/:otEntityID/:otEntityTreeID/:searchInput?`} // subgrid displaying the viewer of history changes
                render={r => (
                    <DocumentsView
                        key={r.location.key}
                        isHistoryViewer={true}
                    />
                )}
            />
            <Route
                path={`/:fileType/:element/:elementID/:hasDocuments/:otEntityID/:otEntityTreeID?/:searchInput?`} // OTEntityTreeID
                render={r => <DocumentsView key={r.location.key} />}
            />

            {/* ONGOING : REPORTING GENERIC DATAGRID TEST */}
            <Route
                path={`${dataGrid}${queryViewReporting}/:elementID/Reporting`}
                render={r => (
                    <GenericDataGrid isReporting key={r.location.key} />
                )}
            />
            <TreeRoutesMemo />
            <Redirect from="/" to="/home" />
        </Switch>
    );
};

export default MenuRouter;
